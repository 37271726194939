@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 font-body antialiased text-black bg-gray-100
  }

  #root {
    @apply flex flex-col text-body
  }

  code {
    @apply font-code
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-gray-200
  }
  *::-webkit-scrollbar-track {
    @apply bg-gray-100
  }
}

@layer utilities {
  .text-large-title {
    font-size: 34px;
    line-height: 40px;
  }

  .text-title-1 {
    font-size: 28px;
    line-height: 34px;
  }

  .text-title-2 {
    font-size: 22px;
    line-height: 28px;
  }

  .text-title-3 {
    font-size: 20px;
    line-height: 24px;
  }

  .text-subtitle-1 {
    font-size: 17px;
    line-height: 22px;
  }
  
  .text-subtitle-2 {
    font-size: 15px;
    line-height: 20px;
  }

  .text-body {
    font-size: 13px;
    line-height: 18px;
  }

  .text-caption-1 {
    font-size: 12px;
    line-height: 16px;
  }
  
  .text-caption-2 {
    font-size: 11px;
    line-height: 14px;
  }
}


.ant-btn-primary {
  @apply bg-blue;
}

// FOR SOME REASONS RISES ERROR WITH TW
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/** TODO: Update to tw */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
